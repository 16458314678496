import styled from "styled-components";

const TitleContainer = styled.div`
  color: #fff;
  width: 100%;
  text-align: center;
  background: #f8f9fa;
  box-shadow: 0 20px 20px -20px #dde0e5;
`;
export default TitleContainer;
