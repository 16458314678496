import React, { Component } from "react";
import styled from "styled-components";
import fetch from "isomorphic-fetch";

import Helmet from "react-helmet";

import { Link } from "gatsby";

import Label from "components/Elements/Label";
import Button from "components/Elements/Button";

import { Row, Col } from "react-grid-system";

import TitleContainer from "components/Elements/TitleContainer";
import TitleHeader from "components/Elements/TitleHeader";

const Container = styled.div`
  font-size: 20px;
  margin: auto;
  max-width: 95%;
`;
const Message = styled.p`
  line-height: 1.5;
  font-size: 25px;
`;

const SubQuote = styled.h1`
  color: #333;
  font-size: 30px;
  padding: 0;
  margin: 0;
`;

const Testimony = styled.h3`
  color: #333;
  font-size: 36px;
  background: #f4f4f4;
  padding: 0;
  text-align: center;
  padding: 5%;
  margin: 0;


  .author {
    font-size: 25px;
  }
`;

const Title = styled.h1`
  font-weight: 600;
  text-align: center;
  font-size: 1.4em;
  color: #191919;
  @media only screen and (max-width: 1024px) {
    margin-top: 5px;
  }
`;

const Card = styled.div`
  height: 350px;
  margin: 20px;
  background: #fcfcfc;
  -moz-box-shadow: 0 0 5px #e4e4e4;
  -webkit-box-shadow: 0 0 5px #e4e4e4;
  box-shadow: 0 0 5px #e4e4e4;
  transition: all 100ms ease;

  &:hover {
    -moz-box-shadow: 0 0 30px #e4e4e4;
    -webkit-box-shadow: 0 0 30px #e4e4e4;
    box-shadow: 0 0 30px #e4e4e4;
  }
`;

const Img = styled.div`
  background-image: url(${props => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 210px;
`;

const Center = styled.div`
  text-align: center;
  img,
  span {
    display: inline;
  }
`;

class ContributePage extends Component {
  constructor(props) {
    super(props);
    this.getViews = this.getViews.bind(this);

    this.state = { neuralnetwork: "", ycombinator: "" };
  }

  componentDidMount() {
    this.getViews("y-combinator");
    this.getViews("neural-network");
  }

  getViews(id) {
    fetch(`https://enlight-views.firebaseio.com/views/${id}.json`)
      .then(response => response.json())
      .then(data => {
        if (id === "y-combinator") {
          this.setState({
            ycombinator: data
          });
        } else if (id === "neural-network") {
          this.setState({
            neuralnetwork: data
          });
        }
      });
  }

  render() {
    return (
      <div>
        <Helmet title="Contribute | Enlight" />
        <div className="text-center">
          <h1 className="text-4xl tracking-tight leading-snug font-extrabold text-gray-800 mt-4">Share Your Knowledge</h1>
          <h2 className="text-2xl tracking-tight leading-snug font-extrabold text-gray-700 mt-4">
            Reach 50,000+ developers who are eager to learn new technologies.{" "}
          </h2>
        </div>
        <Container className="text-gray-700 mt-6">
          <Row>
            <Col lg={4} md={6} xs={12}>
              <Message>
                Enlight is built to be a community of learners who learn, make,
                and share fun programming projects with each other.
              </Message>
              <br/>

              <Message>
                <b>
                  Join the contributor community and share your knowledge with
                  the world.
                </b>
              </Message>
              <br/>
              
              <Message>
                <b>
                  <a href="https://www.notion.so/Content-Guidelines-d704bbc275484a07b618af54bdf05e26">
                  Read some of our suggestions for how to write a tutorial on Enlight here!
                  </a>
                </b>
              </Message>
              <br/>

              <Link to="/submit">
                <Button type="submit">
                  Submit a project
                </Button>
              </Link>
            </Col>

            <Col lg={4} md={6} xs={12}>
              <a href="/neural-network#project">
                <Card className="project">
                  <Img src="/img/nn.png" alt="Neural Network" />
                  <br/>

                  <Title>Neural Network</Title>
                  <Label>
                    <Center>
                      <img className="h-6" height="25px" alt="Chart" src="/img/line-chart.png" />
                      <span> {this.state.neuralnetwork} reads </span>
                    </Center>
                  </Label>
                </Card>
              </a>
            </Col>
            <Col lg={4} md={6} xs={12}>
              <a href="/y-combinator#project">
                <Card className="project">
                  <Img src="/img/y-comb.png" alt="Y Combinator" />
                  <br/>

                  <Title>Deriving the Y Combinator</Title>

                  <Label>
                    <Center>
                      <img className="h-6" height="25" alt="Chart" src="/img/line-chart.png" />
                      <span> {this.state.ycombinator} reads </span>
                    </Center>
                  </Label>
                </Card>
              </a>
            </Col>
          </Row>
          <br />
        </Container>
        <Row>
          <Testimony>
            <p>
            “Enlight gave me a platform to share my journey of discovery about a
            topic I was super interested in for a while. It was awesome seeing
            how many people enjoyed my work, and it inspired me to keep writing
            and teaching on my own.”{" "}
            <span class="author">
              —{" "}
              <a href="https://enlight.nyc/user/nathanhleung">
                Nathan Leung
              </a>
            </span>
            </p>
          </Testimony>
        </Row>

        <Container>
          <br />
          <Row>
            <Col lg={4} md={6} xs={12}>
              <Message>
                <img className="h-6" height="25" alt="Flash" src="/img/flash.png" />
                <b>Get published quickly.</b>
                <br />
                Have an interesting project sitting on your computer? Write a
                tutorial and it will be approved by Enlight staff in less than
                24 hours. Cross-posted content, such as tutorials posted on
                Medium or on your blog, is welcome as well.
              </Message>
              <br/>

            </Col>
            <Col lg={4} md={6} xs={12}>
              <Message>
                <img className="h-6" height="25" alt="Chart" src="/img/line-chart.png" />
                <b> Gain exposure.</b>
                <br />
                Want to reach more people? Projects on Enlight typically recieve
                thousands of views and are a great way to build up your
                portfolio. We leverage our mailing list, community, and SEO to
                spread your work around the web.
              </Message>
              <br/>

            </Col>
            <Col lg={4} md={6} xs={12}>
              <Message>
                <img className="h-6" height="25" alt="People" src="/img/people.png" />
                <b> Join the contributor community.</b>
                <br />
                Become a part of the Enlight family of contributors. Meet new
                people and work together to spread CS education throughout the
                world.
              </Message>
              <br/>

            </Col>
          </Row>
          <Link to="/submit">
            <Button type="submit">
              Submit a project
            </Button>
          </Link>
        </Container>
      </div>
    );
  }
}

export default ContributePage;
